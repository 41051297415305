import { Splide } from "@scripts/splide-slider.js";

export default class SliderInterface {
  createSlider(target = null) {
    throw new Error("createSlider() method must be implemented");
  }

  setParams(params) {
    throw new Error("setParams() method must be implemented");
  }

  mountSlider(sliderObject) {
    throw new Error("mountSlider() method must be implemented");
  }

  setSync(sliderObject, syncObject) {
    throw new Error("setSync() method must be implemented");
  }

  createSliderWithParams(target = null, params = {}) {
    throw new Error("createSliderWithParams() method must be implemented");
  }
}
