import SplideSlider from "@scripts/components/sliders/SplideSlider.js";
import domReady from "@roots/sage/client/dom-ready";
import Debugger from "@scripts/core/Debugger.js";

class HeroSlider {
  constructor() {
    this.sliderObject = new SplideSlider();
    this.heroSliderDomElement = document.querySelector(
      ".js-hero-slider .splide",
    );
    this.decorImage = document.querySelector(
      ".js-hero-slider [data-hero-slider-decor]",
    );
    this.slider = null;
  }

  init() {
    if (!this.heroSliderDomElement) {
      Debugger.debug("Hero slider element not found", null, "warn");
      return;
    }

    this.slider = this.sliderObject.createSlider(this.heroSliderDomElement);
    this.sliderObject.mountSlider(this.slider);

    this.setupEventListeners();
  }

  setupEventListeners() {
    this.sliderObject.callbackEvents(
      this.slider,
      "move",
      this.handleMove.bind(this),
    );
    this.sliderObject.callbackEvents(
      this.slider,
      "mounted",
      this.handleMounted.bind(this),
    );
  }

  handleMove(newIndex, prevIndex, destIndex) {
    Debugger.debug("newIndex", newIndex, "info");
    Debugger.debug("destIndex", destIndex, "info");

    const singleSlideObject = this.sliderObject.getSlide(
      this.slider,
      prevIndex ?? 0,
    );
    this.updateDecorVisibility(singleSlideObject);
  }

  handleMounted() {
    const singleSlideObject = this.sliderObject.getSlide(this.slider, 0);
    this.updateDecorVisibility(singleSlideObject);
  }

  updateDecorVisibility(slideObject) {
    Debugger.debug("singleSlideObject", slideObject, "info");

    const slideDomElement = slideObject?.slide;
    if (!slideDomElement) return;

    const showDecor = slideDomElement?.dataset.heroSliderShowDecor;
    Debugger.debug("showDecor", showDecor, "info");

    this.decorImage.style.opacity =
      showDecor === "true" || showDecor === true ? "0" : "1";
  }
}

function initializeDefaultSliders() {
  const sliderObject = new SplideSlider();
  const elms = document.querySelectorAll(
    ".splide:not(.js-prevent-default-slider)",
  );

  elms.forEach((elm) => {
    const slider = sliderObject.createSlider(elm);
    sliderObject.mountSlider(slider);
  });
}

domReady(async () => {
  initializeDefaultSliders();

  const heroSlider = new HeroSlider();
  heroSlider.init();
});
