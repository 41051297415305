import Splide from "@scripts/splide-slider.js";
import SliderInterface from "@scripts/components/sliders/SliderInterace.js";

export default class SplideSlider extends SliderInterface {
  constructor() {
    super();
  }

  createSlider(target = null) {
    return new Splide(target);
  }

  createSliderWithParams(target = null, params = {}) {
    return new Splide(target, params);
  }

  mountSlider(sliderObject) {
    if (sliderObject instanceof Splide) {
      sliderObject.mount();
    }
  }

  setParams(sliderObject, params) {
    if (sliderObject instanceof Splide) {
      Object.assign(sliderObject.options, params);
    }
  }

  setSync(sliderObject, syncObject) {
    if (sliderObject instanceof Splide) {
      sliderObject.sync(syncObject);
    }
  }

  callbackEvents(sliderObject, event, callback) {
    if (sliderObject instanceof Splide) {
      sliderObject.on(event, callback);
    }
  }

  getSlide(sliderObject, index) {
    if (sliderObject instanceof Splide) {
      return sliderObject.Components.Slides.getAt(index);
    }
  }
}
